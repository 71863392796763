.extEmbdVideo {
  padding: 1rem;
  background-color: #e5f2f4;
  color: #293d4f;
}
[id^="give-consent"] a,
[id^="give-consent"] a:visited {
  color: #65b32e !important;
  text-decoration: none;
}
[id^="give-consent"] a:hover {
  color: #65b32e !important;
  text-decoration: underline;
}
.extEmbdVideoDisclaimer {
  font-size: 0.65rem;
  color: #4d4d4d;
}
